@media  (min-width: 425px) and (max-width: 992px) {
.login_form_block{
    width: 80%;
}
.otp_form_block{
    width: 58%  !important;
}
}
@media  (min-width: 769px) and (max-width: 992px) {
    .login_form_block{
        width: 60% !important;
    }
    .otp_form_block{
        width: 60% !important;
    }
    }