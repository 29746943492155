.routerContainer {
  display: flex;
  min-height: 100vh;
  /* width: 100%; */
  background-image: url("/public/assets/login.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* background-attachment: fixed; */
  /* position: fixed; */
}
/* SideBar css start */
.sidebar_mainContainer {
  display: flex;
  /* flex-direction: column; */
  justify-content: start;
}
.sidebar_container {
  min-height: 100vh;
  /* background-color: #000000; */
}
.sidebar_companyLogo_field {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar_companyLogo_img {
  height: 80px;
}
.sidebar_menu {
  /* justify-content: space-between; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100vh;
  overflow: hidden; */
  padding: auto 20px;
}
/* .css-ewdv3l {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
} */
.css-1cuxlhl {
  display: flex;
  align-items: center;
}
.sidebar_menuItem {
  font-size: 16px;
  font-weight: 500;
  /* border-radius: 6px; */
  /* background-color: #fff; */
  background-color: rgba(73, 89, 170, 0.6);
  margin: 6px 0px;
}
.sidebar_subMenu_items {
  display: flex;
  justify-content: end;
}
.sidebar_subMenu_subHeading {
  width: 140px;
  height: 50px;
  border: none;
  padding-right: 6px;
  display: flex;
  justify-content: end;
  align-items: center;
}
.sidebar_subMenu_subHeading:hover {
  background-color: rgba(73, 89, 170, 0.6);
  color: #fff;
  /* border-radius: 6px; */
}
.css-1t8x7v1 > .ps-menu-button:hover {
  background-color: #fff !important;
  color: black;
  /* border-radius: 6px; */
}
.css-16jesut > .ps-menu-button:hover {
  background-color: #fff !important;
  color: black;
  /* border-radius: 6px; */
}
.css-1tqrhto > .ps-menu-button:hover {
  background-color: #fff !important;
  color: black;
  /* border-radius: 6px; */
}
.css-1tqrhto > .ps-menu-button {
  text-align: end;
  padding: 0px 0px !important;
}
.sidebar_subMenu {
  font-size: 16px;
  font-weight: 500;
  /* border-radius: 6px; */
  background-color: rgba(73, 89, 170, 0.6);
  /* background-color: #fff; */
  margin: 6px 0px;
  margin-right: 6px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  /* filter: drop-shadow(3px 3px 3px rgba(73, 89, 170, 0.6)); */
}
.sidebar_menuItem--logout {
  background-color: #4959aa;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  /* border-radius: 6px; */
  margin: 10px 0px;
  filter: drop-shadow(3px 3px 3px rgba(73, 89, 170, 0.6));
}

/* SideBar css end */

/* addTicket css start */
.addTicket_innerField_container {
  border-radius: 10px;
}
.addTicket_mainContainer {
  min-height: 100vh;
  /* width: 100%; */
  background-image: url("/public/assets/login.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* background-attachment: fixed; */
  /* position: fixed; */
  display: flex;
  justify-content: center;
  align-items: start;
  /* overflow-y: scroll; */
}
.addTicket_innerContainer_header {
  border-radius: 10px;
}
.addTicket_headed_text {
  background-color: #4959aa;
  color: #fff;
  font-size: 26px;
  font-weight: 500;
  border-radius: 10px 10px 0px 0px;
}
.addTicket_inputField_container {
  background-color: #ffffff9c;
  /* opacity: 60%; */
  box-shadow: 5px 5px 10px 0px #4959aa80;
  border-radius: 0px 0px 15px 15px;
  padding: 33px 0px;
}
.addTicket_ticket_label {
  /* width: 250px; */
  font-size: 22px;
  font-weight: 700;
}
.--addTicket_inputFields {
  /* margin-bottom: 10px; */
  text-align: start;
  font-size: 20px;
  padding-left: 20px;
  height: 40px;
  width: 60%;
  background: #4959aa99;
  border: none;
  border-radius: 10px;
}
.addTicket_DateInput_inputFields {
  /* width: 100% !important; */
  text-align: start;
  font-size: 20px;
  padding-left: 20px;
  height: 40px;
  width: 80%;
  background: #4959aa99;
  border: none;
  border-radius: 10px;
  /* color: #fff; */
}
.--addTicket_checkBox_inputFields {
  /* margin-bottom: 20px; */
  text-align: start;
  font-size: 20px;
  padding-left: 20px;
  height: 25px;
  width: 35%;
  background: #4959aa99;
  border: none;
  border-radius: 10px;
}
.addCombo_checkBox_inputFields {
  /* margin-bottom: 20px; */
  text-align: start;
  font-size: 20px;
  margin-right: 10px;
  height: 25px;
  /* width: 35%; */
  width: 35px;
  background: #4959aa99;
  border: none;
  border-radius: 10px;
}

.--addTicket_description_inputFields {
  margin-bottom: 20px;
  text-align: start;
  font-size: 20px;
  padding-left: 20px;
  height: 140px;
  width: 60%;
  background: #4959aa99;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.--addTicket_uploadImage_inputFields {
  /* margin-bottom: 20px; */
  text-align: start;
  font-size: 20px;
  padding-left: 20px;
  height: 40px;
  width: 60%;
  /* background: #4959aa4a; */
  background: #929dd399;
  border: 2px dashed #4959aa99;
  border-radius: 10px;
}
.addTicket_uploadImage_inputFields {
  /* margin-bottom: 20px; */
  text-align: start;
  font-size: 20px;
  padding-left: 20px;
  height: 40px;
  width: 50%;
  /* background: #4959aa4a; */
  background: #929dd399;
  border: 2px dashed #4959aa99;
  border-radius: 10px;
}
.addTicket_uploadImage_input::-webkit-file-upload-button {
  visibility: hidden;
}
.addTicket_uploadImage_input {
  /* opacity: 0; */
  width: 92%;
}
.addTicket_submitBtn_field {
  display: flex;
  /* justify-content: center; */
}

.trending_submitBtn_field {
  display: flex;
  justify-content: center;
}
.addTicket_submitBtn {
  background-color: #4959aa;
  border: none;
  border-radius: 15px;
  padding: 10px 0px;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  width: 60%;
}

/* .--model-filed{
  
  width: calc(100% - -89px);
}
.--model{
  width: calc(100% - 44%);

} */
/* addTicket css end  */

/* addUser css start */
.addUser_submitBtn_field {
  display: flex;
  justify-content: center;
}
.addUser_ticket_label {
  font-size: 22px;
  font-weight: 700;
}
.addUser_companyAdd_label {
  /* width: 190px; */
  font-size: 22px;
  font-weight: 700;
}
.addUser_submitBtn {
  background-color: #4959aa;
  border: none;
  border-radius: 15px;
  padding: 10px 0px;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  /* width: 60%; */
}
.--addUser_inputFields {
  margin-bottom: 20px;
  text-align: start;
  font-size: 20px;
  padding-left: 20px;
  height: 40px;
  width: 50%;
  background: #4959aa99;
  border: none;
  border-radius: 10px;
}
.--addUser_companyName_inputField {
  width: 75%;
  margin-bottom: 20px;
  text-align: start;
  padding-left: 10px;
  font-size: 20px;
  margin-left: 8px;
  height: 40px;
  background: #4959aa99;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}
.addUser_logoSelect_input::-webkit-file-upload-button {
  visibility: hidden;
}
/* .addUser_logoSelect_input::-webkit-file-name-text {
  display: none;
} */
/* .addUser_logoSelect_input::-webkit-file-name-text {
            color: white;
        } */

.addUser_logoSelect_input {
  /* margin-bottom: 20px;
  font-size: 20px;
  padding-left: 20px;
  border: none;
  border-radius: 10px; */
  /* opacity: 0; */
  /* text-align: left; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.addUser_logoSelect_inputContainer {
  margin-bottom: 20px;
  /* text-align: start; */
  font-size: 20px;
  /* padding-left: 20px; */
  height: 40px;
  width: 50%;
  background: #4959aa99;
  border: none;
  border-radius: 10px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
/* addUser css end */

/* salesReport css start */

.salesReport_tableField_container {
  background-color: #ffffff9c;
  box-shadow: 5px 5px 10px 0px #4959aa80;
  border-radius: 15px;
}
.salesReport_tableHead {
  background-color: #4959aa !important;
  /* height: 50px !important; */
  color: white !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  text-align: center !important;
  border-right: 3px solid white;
  margin-bottom: 20px !important;
}

.salesReport_tableData {
  height: 70px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  text-align: center !important;
  border-bottom: 3px solid #4959aa !important;
  border-right: 3px solid #4959aa !important;
  margin-top: 20px !important;
}

.salesReport_themePark_select {
  text-transform: capitalize;
  width: 100%;
  height: 38px;
  border: 2px solid #4959aa;
  border-radius: 6px;
  background-color: #4959aa;
  color: #fff;
}
.salesReport_datePicker_startToEnd {
  height: 38px;
  border: 2px solid #4959aa;
  border-radius: 6px;
  padding-left: 10px;
}
/* salesReport css end */

/* new booking css start */
.newBooking_filedInnerContainer {
  min-height: 100%;
  margin: auto;
  border-radius: 15px;
}
.newBooking_background_image {
  height: 320px;
  border-radius: 15px 15px 0px 0px;
  margin: auto;
  background-image: url("/public/assets/edit_back.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
  /* padding-bottom: 5px; */
}
.--newBooking_search_inputFields {
  height: 38px;
  width: 90%;
  border-radius: 6px;
  border: 2px solid #4959aa;
  padding-left: 10px;
  background-color: #4959aa;
  color: #fff;
}
.--newBooking_search_inputFields::placeholder {
  color: white;
}
.newBooking_inputForm_Field {
  padding: 0px 30px 15px 0px;

  display: flex;
  justify-content: end;
}
.newBooking_TicketField {
  /* margin: auto; */
  padding-top: 20px;
  height: auto;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  /* overflow-x: scroll; */
}
.newBooking_themePark_field {
  border-radius: 10px;
}
.newBooking_ticketType {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.newBooking_btnField {
  margin: auto;
  display: flex;
  justify-content: center;
  border-radius: 0px 0px 15px 15px;
}
.newBooking_btn {
  height: 50px;
  background-color: #4959aa;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 22px;
}
.newBooking_addSub_btn {
  font-size: 25px;
  font-weight: 700;
  cursor: pointer;
}
.newBooking_themePark_image {
  height: 270px;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
}
.newBooking_ticketDetails_field {
  background-color: #4959aa;
  padding-left: 10px;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  justify-content: center;
}
.newBooking_addSub_ticketField {
  height: 25px;
  width: 26%;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newBooking_slotModel {
  height: 150px;
  width: 100%;
  background-image: url("/public/assets/edit_back.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  justify-content: center;
}
.newBooking_TicketSlot_Label {
  font-size: 15px;
  font-weight: 500;
}
.--newBooking_ticketSlot_inputFields {
  width: 100%;
  background-color: #4959aa;
  color: #fff;
  border: none;
  border-radius: 15px;
  height: 38px;
  padding: 0px 10px;
}
.react-datepicker-wrapper {
  width: 100%;
}
.--newBooking_ticketSlotDate_inputFields {
  width: 100% !important;
  background-color: #4959aa;
  color: #fff;
  border: none;
  border-radius: 15px;
  height: 38px;
  padding: 0px 10px;
}

.newBooking_SlotSubmit_btnField {
  margin: auto;
  display: flex;
  justify-content: center;
}
.newBooking_slotSubmit_btn {
  width: 60%;
  height: 40px;
  background-color: #4959aa;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 600;
}
.newBooking_ticketText {
  font-size: 15px;
  font-weight: 700;
}
.newBooking_ticketDetail_first {
  border-bottom: 2px black dashed;
}

/* new booking css end */

/* notification css start */
.notification_description_inputFields {
  margin-bottom: 20px;
  text-align: start;
  font-size: 20px;
  padding: 10px;
  height: 340px;
  width: 100%;
  background: #4959aa99;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.notification_description_inputFields::placeholder {
  color: #fff;
}
.notification_submitBtn {
  background-color: #4959aa;
  border: none;
  border-radius: 15px;
  padding: 10px 0px;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  width: 80%;
}
/* notification css end */

/* viewCombo css start */
.viewCombo_tableHead {
  background-color: #4959aa !important;
  /* height: 20px !important; */
  color: white !important;
  font-size: 16px !important;
  padding: 10px 5px !important;
  font-weight: 700 !important;
  text-align: center !important;
  border-right: 2px solid black;
  margin-bottom: 20px !important;
}
.viewCombo_tableData {
  background-color: #fff;
  padding: 10px 5px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-align: center !important;
  border-right: 2px solid #000 !important;
}
.viewCombo_editIcon_tableData {
  background-color: #fff;
  padding: 10px 5px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-align: center !important;
}

/* viewCombo css end */

/* edit Combo css start */
.editComboLabel {
  font-size: 18px;
  font-weight: 800;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.editCombo_selectCombo {
  height: 40px;
  border-radius: 5px;
  font-family: Roboto Flex;
  font-size: 14px;
  font-weight: 400;
  box-shadow: 4px 4px 10px 0px #4959aa;
  color: #000000;
  width: 38%;
  border: none;
  /* margin-left: 25px; */
}
.editComboSelectBox {
  /* width: 100%; */
  height: 80px;
  overflow: auto;
  word-break: keep-all;
  overflow-wrap: normal;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 4px 4px 10px 0px #4959aa;
}
.editComboPriceInputField {
  height: 32px;
  width: 30%;
  border: none;
  border-radius: 6px;
  padding: 6px;
  box-shadow: 4px 4px 10px 0px #4959aa;
}
.editCombo_submitBtn {
  background-color: #4959aa;
  border: none;
  border-radius: 15px;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  width: 47%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* edit Combo css end */
