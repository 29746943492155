@import url(./App.css);
@import url(./popup.css);
@import url(./media.css);
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
.side_bar_none {
  display: none;
}
.main_Section {
  background-image: url('/public/assets/login.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  min-height: 100vh;
  /* width: 100vw; */
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.delete_ticket_image {
  width: 22px;
  height: 22px;
}
.passwordBlock {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 1px solid red;
}
.login_block {
  margin: 120px 0px 80px 20px;
}
.treat_logo {
  height: 130px;
  width: 132.79px;
}
.image_icon {
  width: 100%;
  height: 100%;
}
.login_form_block {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 10px 10px 10px rgba(10, 49, 109, 0.6);
  border-radius: 20px;
  padding: 23px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 38%;
}
.form_block {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* align-items: center; */
}
.reset_input_block {
  background: transparent;

  box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0px !important;
}
.reset_input_block:focus {
  border: 0.7px solid #000000;
}
.reset_input_field {
  background: transparent;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  padding: 15px 5px 15px 10px;
  /* identical to box height */
  width: 100%;
  color: #828282;
}
.reset_input_field:focus {
  outline: none;
}
.login_input_field {
  background: transparent;
  border: 0.7px solid #000000;
  box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 15px 5px 15px 10px;
  margin: 10px 0px;
  border: none;
  /* Username */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */
  width: 100%;
  color: #828282;
}
.login_button {
  background: #4959aa;
  outline: none;
  border: none;
  border-radius: 35px;
  font-family: 'Inter';
  font-style: normal;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  color: #cdcdcd;
  padding: 8px 80px 8px 80px;
  transition: transform 0.3s ease;
  width: 70%;
}
.login_button:hover {
  background-color: #ffff;
  color: #4959aa;
}
.login_para {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* line-height: 29px; */
  /* text-decoration-line: underline; */
  color: rgb(61, 61, 236);
}
.login_para:hover {
  cursor: pointer;
}
/* *******LOGIN END******* */

/********GET OTP ******** */
.otp_form_block {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 10px 10px 10px rgba(10, 49, 109, 0.6);
  border-radius: 20px;
  padding: 50px 40px 50px 40px;
  width: 38%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.otp_block {
  margin: 140px 0px 80px 20px;
}
.otp_sec_text {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
}
.otp_button {
  background: #4959aa;
  outline: none;
  border: none;
  border-radius: 35px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: hsl(0, 0%, 80%);
  padding: 15px 60px 15px 60px;
}
.otp_button:hover {
  background-color: #ffff;
  color: #4959aa;
}

/* ********GET OTP END********* */

.otp_submit_block {
  margin: 130px 0px 80px 20px;
}
.otp_submit_form_block {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 10px 10px 10px rgba(10, 49, 109, 0.6);
  border-radius: 20px;
  padding: 60px 40px 60px 40px;
  width: 38%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.otp_submit_button {
  background: #4959aa;
  outline: none;
  border: none;
  border-radius: 35px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: hsl(0, 0%, 80%);
  padding: 8px 55px 8px 55px;
}
.otp_submit_button:hover {
  background-color: #ffff;
  color: #4959aa;
}

/* *********RESET START********** */
.reset_button {
  background: #4959aa;
  outline: none;
  border: none;
  border-radius: 35px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #ffff;
  padding: 10px 85px 10px 85px;
}
.reset_form_block {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 10px 10px 10px rgba(10, 49, 109, 0.6);
  border-radius: 20px;
  padding: 40px 40px 25px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 38%;
}
.reset_button:hover {
  background-color: #ffff;
  color: #4959aa;
}

.popup_box {
  position: absolute;
  top: 25%;
  bottom: 25%;
  left: 25%;
  right: 25%;
  opacity: 1;
  background: #ffffff;
  border-radius: 15px;
}
/* .popup_image{
  background-image: url("/public/assets/resetpassword.webp");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
height: auto;
} */
.reset_text {
  font-family: Roboto Flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 14.06px;
  text-align: center;
}
.reset_ok_btn {
  padding: 7px 27px 7px 27px;
  gap: 10px;
  border-radius: 20px 20px 20px 20px;
  opacity: 0px;
  border: none;
  font-family: Roboto Flex;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: left;
  background: #929bcc;
  color: #ffffff;
}
.thankyou_text {
  font-family: Roboto Flex;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  position: absolute;
  top: 35%;
  left: 38%;
}
.imageSize {
  width: 100%;
  height: 100%;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.imageSize.rotate {
  transform: rotate(90deg);
}
.editImageBlock {
  width: 20px;
  height: 20px;
}
/* .addUserPasswordIcon{
  width:50px;
  height:40px;
} */
/* ADD SUBTICKET */

.addsubticket_header {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  color: #ffffff;
}
.addsubticket_block {
  border-radius: 10px 10px 0px 0px;
  background: #4959aa;
}
.addsubticket_form_block {
  background: rgba(255, 255, 255, 0.6);

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 10px;
  /* height:calc(100vh-150px);
  margin: auto; */
}

.addsubticket_label {
  font-family: Roboto Flex;
  font-size: 24px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: left;
  color: #000000;
}
.add_SubTicket_InputField {
  width: 70%;
  background: #4959aa99;
}
.addsubticketInputField {
  background: #4959aa99;
  border: none;
  padding: 8px 0px 8px 5px;
  border-radius: 10px;
  width: 100%;
}
.addsubticketImageInputField {
  background: #4959aa4a;
  /* display: none; */
  border: 2px dotted #4959aa;
  padding: 8px 0px 8px 5px;
  border-radius: 5px;
  width: 100%;
}

.addSubTicketBlock {
  width: 80%;
}
.addSubTicketTextField {
  background: #4959aa99;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  width: 100%;
  height: 110px;
}

.addsubticket_submit {
  font-family: Roboto Flex;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  background: #4959aa;
  border: none;
  padding: 3px 160px;
  border-radius: 10px;
  /* width: 100%; */
}
/* TICKET VIEW BLOCK */
.ticket_view {
  display: flex;
}
.ticket_view_form_block {
  background: #ffffff;
  border-radius: 15px;
  padding-bottom: 15px;
  min-height: 95vh;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
}
.view_ticket_heading {
  font-family: Roboto Flex;
  font-size: 36px;
  font-weight: 700;
  line-height: 42.19px;
  text-align: left;
  color: #ffffff;
}
.view_ticket_heading_block {
  background: #4959aa;
  border-radius: 15px 15px 0px 0px;
  padding: 35px 0px 22px 40px;
}
.view_ticket_sub_heading {
  font-family: Roboto Flex;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.13px;
  text-align: left;
  color: #000000;
}
.view_ticket_block {
  /* border-bottom: 2px solid #4959aa; */
  cursor: pointer;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
}

.table_container {
  max-height: 350px !important;
  overflow: auto;
}

.mgm_table_data_head {
  font-family: Roboto Flex !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  text-align: center !important;
  background: #4959aa !important;
  color: #ffffff !important;
  padding: 18px !important;
}
.mgm_table_data {
  font-family: Roboto Flex !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-align: center !important;
  padding: 18px !important;
}
.edit_image {
  cursor: pointer;
  width: 18px;
  height: 18px;
}
.edit_page_form_block {
  background: #ffffff;
  border-radius: 15px !important;
  height: auto !important;
  /* overflow-y: auto; */
}

.edit_page_background_image {
  background-image: url('/public/assets/edit_back.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 25%;
  overflow: hidden;
  background-position: center;
  border-radius: 15px 15px 0px 0px;
}
.edit_icons {
  padding: 7px 27px 7px 27px;
  border-radius: 15px;
  background: #4959aa;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.edit_ticket_image {
  width: 10px;
  height: 16px;
}
.edit_delete_icon {
  width: 15px;
  height: 15px;
}
.edit_ticket_btn {
  font-family: Roboto Flex;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}
.editPageInputBlock {
  display: flex;
  justify-content: center;
  padding: 15px 0px;
}
.editPageInputField {
  background: #4959aa99;
  border: none;
  padding: 7px 10px 7px 10px;
  border-radius: 5px;
  width: 100%;
}
.editPageInputContainer {
  width: 100%;
  border: 1px solid red;
}

/* BOKKING MANAGEMENT START */
.booking_form_block {
  background-color: #ffff;
  border-radius: 15px;
  overflow-y: auto;
}

.booking_image {
  background-image: url('/public/assets/edit_back.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 330px;
  border-radius: 15px 15px 0px 0px;
}
.bookingEditInput {
  /* height: 40px; */
  padding: 7px 5px;
  border-radius: 5px;
  background: #4959aa99;
  border: none;
  outline: none;
  width: 100%;
}
.editInputBlock {
  width: 75%;
}
.subEditInputBlock {
  width: 45%;
}
.bookingeditlabel {
  font-family: Roboto Flex;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  color: #000000;
}

.--model_label {
  display: flex;
  padding: 20px 75px;
  /* align-items: center;
  justify-content: space-between; */
}
.---model_label {
  display: flex;
  padding: 20px 75px;
  /* align-items: center;
  justify-content: space-between; */
}
.bookingEditTicketSubmitButton {
  padding: 8px 120px;
  border-radius: 10px;
  background: #4959aa;
  border: none;
  font-family: Roboto Flex;
  font-size: 36px;
  font-weight: 700;
  line-height: 42.19px;
  text-align: left;
  color: #ffffff;
}
.booking_table_view {
  border-radius: 15px;
  background: #ffffff99;
}
.bookingEditBtnContainer {
  display: flex;
  justify-content: end;
  /* gap: 80px; */
  /* padding: 25px 115px; */
}

.bookingtableheading {
  background: #4959aa;
}
.bookingtablehead {
  font-family: Roboto Flex !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  text-align: center !important;
  color: #ffffff !important;
  border: 1px solid white;
  font-family: Roboto Flex;
  line-height: 21.09px !important;
  text-align: center;
}
.bookingtabledata {
  font-family: Roboto Flex !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 21.09px !important;
  text-align: center !important;
  color: black !important;
  border: 1px solid #4959aa !important;
}
.tablebody {
  background-color: #ffffff !important;
  cursor: pointer;
}
.table-container {
  max-height: 550px;
  overflow-y: auto;
}
.input_date_field {
  border-radius: 5px;
  border: none;
}
.date_start {
  font-family: Roboto Flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: left;
  color: #000000;
  padding-top: 6px;
}
.download_btn {
  font-family: Roboto Flex;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  background: #4959aa;
  border: none;
  border-radius: 7px;
  cursor: pointer;
}
.product_paragraph_footer {
  font-family: Roboto Flex;
  font-size: 21px;
  font-weight: 700;
  line-height: 26.93px;
  text-align: left;
  display: flex;
  justify-content: start;
}
.booking_theme_park_select_option {
  width: 124px;
  height: 37px;
  border-radius: 8px;
  background: #ffffff;
}
/* BOOKING MANAGEMENT END */
.verification_form_block {
  border-radius: 0px 0px 10px 10px;
  min-height: 85vh;
  /* width: 100%; */
  background: #ffffff99;
  box-shadow: -5px -5px 10px 0px #4959aa80;
  box-shadow: 5px 5px 10px 0px #4959aa80;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* padding-top: 90px; */
}
.verificationInputBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}
.verificationInputField {
  padding: 16px 10px;
  border-radius: 10px;
  background: #4959aa66;
  border: none;
  width: 36%;
  font-family: Roboto Flex;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: left;
  color: #00000080;
}
.verificationButton {
  font-family: Roboto Flex;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.06px;
  text-align: center !important;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  border-radius: 25px;
  background: #4959aa;
  width: 20%;
}
.verificationButton:hover {
  background: #ffffff;
  color: #4959aa;
}
.verificationTableData {
  background: #ffffff !important;
  font-family: Roboto Flex !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 21.09px !important;
  text-align: center !important;
  border: 1px solid #4959aa !important;
}
.bookingVerificationHeader {
  font-family: Roboto Flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: left;
  color: #ffffff;
}
.addComboFormBlock {
  /* min-height: 100vh; */
  height: 100%;
  border-radius: 15px;
  background: #ffffff99;
  box-shadow: -5px -5px 10px 0px #4959aa80;
  box-shadow: 5px 5px 10px 0px #4959aa80;
}
.addComboHeader {
  background: #4959aa;
  border-radius: 15px 15px 0px 0px;
  font-family: Roboto Flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: left;
  color: #ffffff;
}
.comboInputLabel {
  font-family: Roboto Flex;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
  text-align: left;
  color: #000000;
}
.comboInputField {
  height: 40px;
  border-radius: 5px;
  border: none;
  background: #ffffff;
  width: 38%;
}
.comboAddOption {
  height: 40px;
  border-radius: 5px;
  font-family: Roboto Flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  color: #000000;
  width: 35%;
  border: none;
}

.checkBoxLabel {
  font-family: Roboto Flex;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
  text-align: left;
  color: #000000;
}
.checkBoxInput {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 2px solid #4959aa !important;
  cursor: pointer;
}
.addComboBox {
  width: 65%;
  height: 200px;
  overflow: auto;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 4px 4px 10px 0px #4959aa;
}

.comboPriceInputField {
  background: #ffffff;
  box-shadow: 4px 4px 10px 0px #4959aa;
  height: 40px;
  border-radius: 5px;
  border: none;
  width: 43%;
}
.comboPriceBlock {
  width: 65%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 50px;
}
.comboSubmitBtn {
  padding: 10px 50px 10px 50px;
  border-radius: 30px;
  background: #4959aa;
  box-shadow: 1px 1px 2px 0px #ffffff inset;
  border: none;
  box-shadow: 1px 3px 3px 0px #00000066;
  font-family: Roboto Flex;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.06px;
  text-align: center;
  color: #ffffff;
  width: 43%;
}
.dashboardMainContainer {
  border-radius: 15px;
  background: #ffffffb2;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
 width: 95%;
 height: 95vh;
 overflow: auto;
}
.selectDropDownDashboard {
  width: 124px;
  height: 37px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 2px 2px 1px 0px #4959aa40;
  box-shadow: 2px 2px 1px 0px #4959aa40 inset;
  border: none;
  font-family: Roboto Flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: center;
 color: #000000;
 cursor: pointer;
}


.bar-chart{
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0px 3rem;
}