.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-popup, .success-popup {
  background-color: white;
  padding-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 15px;
  height: auto;
  margin:auto;
  width: 40%;
}
.popUpBackGround{
  width: 100%;
  height: 100%;
  border-radius: 15px 15px 0px 0px;
}
.popUpContent{
  font-family: Roboto Flex;
font-size: 20px;
font-weight: 700;
line-height: 14.06px;
text-align: center;
color: #000000;
}
.popUpContentPara{
  font-family: Roboto Flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 14.06px;
  text-align: center;
  
}
.confirmation-popup p,.success-popup p {
  margin-bottom: 15px;
  font-family: Roboto Flex;
  font-size: 20px;
  font-weight: 550 !important;
  text-align: center;
  padding: 20px;
  color: #000000;
}
.confirmation-popup-background {
  background-image: url("/public/assets/edit_back.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px 5px 0px 0px;
  height: 20vh;
}
.confirmation-popup button{
  padding: 10px 25px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  background: #4959aa !important;
  font-family: Roboto Flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: left;
  color: white;
} 
.success-popup button{
padding: 10px 25px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  background: #4959aa !important;
  font-family: Roboto Flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: left;
  color: white;
}
/* MEDIA QUERY */

@media (max-width: 992px) {
  .--model_label {
    display: flex;
    padding: 20px 10px;
  }
  .bookingEditTicketSubmitButton {
    padding: 5px 70px;
  }
  .---model_label {
    display: flex;
    padding: 20px 0px;
    justify-content: space-around;
  }
.edit_icons {
  padding: 5px 10px 5px 10px;
  
}
.edit_ticket_btn {
  font-size: 10px;
}
.editPageInputBlock{
  gap:12px;
  padding:13px 0px;
}
}
.ticketSlotContainer{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto; 
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding:20px 0px;
}


/* TableFilter.css */

.date-picker-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.date-picker {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.date-picker-input {
  width: 45%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.selected-dates {
  margin-bottom: 20px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.data-table th, .data-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.data-table th {
  background-color: #f0f0f0;
}
.startDate{
  font-family: Roboto Flex;
font-size: 18px;
font-weight: 700;
line-height: 21.09px;
text-align: left;
color: #000000;

}
.startDatePicker{
height: 37px;
outline: none;
width: 70%;
/* border: none !important; */
border-radius: 10px;
background-color: #ffff;
box-shadow: 1px 1px 1px 0px #4959AA99;
box-shadow: 1px 1px 1px 0px #4959AA33 inset;
}
.startDatePickers{
  width: 100%;
  outline: none;
  border: none;
  background-color: #ffff;
  
}